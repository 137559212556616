/* eslint-disable angular/no-services,angular/controller-as,angular/window-service,angular/controller-as-vm */
//TODO use directive
angular.module('views')
    .controller('ViewsCtrl', function($filter, $rootScope, $window, $uibModal, $log, $scope, BASE_PATH, $http, $state, $location, modalService, socketService, ViewPluginService, installationId, viewId, toastr, UserService,plants){
        $scope.popup1 = {
            opened: false
        };
        $scope.open1 = function(){
            $scope.popup1.opened = true;
        };
        $scope.timespan=[
            {hours:2,description:'2 ore'},
            {hours:4,description:'4 ore'},
            {hours:6,description:'6 ore'}
        ];

        var today = new Date();
        today.setHours(today.getHours()-1);
        $scope.filter = {
            selectedTimeSpan:$scope.timespan[0].hours,
            dateTo: new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours()+$scope.timespan[0].hours, today.getMinutes(),today.getSeconds()),
            dateFrom: today
        };
        $scope.dateOptions = {
            formatYear: 'yyyy',
            maxDate: new Date(),
            startingDay: 1
        };
        $scope.installationId = installationId;
        $scope.selectedView = viewId;
        $scope.updateFilter=function(dateFrom,selectedHour){
            $scope.filter.dateTo= new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), dateFrom.getHours()+selectedHour, dateFrom.getMinutes(),dateFrom.getSeconds());
        };
        $scope.changeDateFrom=function(dateFrom){
            if (dateFrom){
                $scope.updateFilter(dateFrom,$scope.filter.selectedTimeSpan);
            }
        };
        $scope.changeTimespan=function(selectedTimeSpan){
            $scope.updateFilter($scope.filter.dateFrom,selectedTimeSpan);
        };

        $scope.plants=plants;
        function isInSTRlistFilter(toBeChecked, stringList){
            toBeChecked = toBeChecked.toLowerCase();
            stringList = stringList.toLowerCase().split(/[,]/);
            return stringList.reduce(function(acc, item){
                return acc || toBeChecked.includes(item.trim().toLowerCase());
            }, false);
        }

        $scope.getColorForView = function(v){
            if(angular.isUndefined($scope.queryCounters)){
                //FIXME wait counter info before render
                return '';
            }
            var counter = $scope.queryCounters[ v.Id ];
            if(angular.isDefined(counter)){
                if(counter > 0){
                    return v.WebColorWithRows;
                }else{
                    return v.WebColorWithNoRows;
                }
            }else{
                //TODO insert default
                return '';
            }
        };
        $scope.getCharFromIconCode = function(v){
            return String.fromCharCode(v.WebIcon);
        };
        $scope.navigateToView = function(v){
            $state.go('views', { installationId: $scope.installationId, viewId: v.Id });
            //$location.path('installation/' + $scope.installationId + '/view/' + v.Id);
        };
        $scope.isSelectedView = function(v){
            // noinspection EqualityComparisonWithCoercionJS
            return v.Id == $scope.selectedView;
        };
        /**
         * On Grid Row selection Handler
         * @param  {object} entity [description]
         * @param  {object} query  [description]
         */
        $scope.onItemSelection = function(entity, query){
            // $log('on item selection handling.');
            // clean current Selected Items List
            query.selectedItemsCommand = 'LOADING';
            $scope.getCommand(entity, query);
            $scope.getMapSymbol(entity, query);
        };
        /**
         * Clean command list by given querylist
         * @param  {object} item      [description]
         * @param  {object} q [description]
         */
        $scope.cleanCommandList = function(item, q){
            q.selectedItemsCommand = [];
            q.mapUrl = null;
        };
        /**
         * Initiate Ws call for command string - command key is contained in selected item at specified field
         * @param  {object} item      [description]
         * @param  {object} queryList [description]
         */
        $scope.getCommand = function(item, queryList){
            var qL = queryList;
            var command = item[ queryList.commandColumnKey ];
            if(command){
                ViewPluginService.getCommand(command, $scope.installationId).then(function(data){
                    qL.selectedItemsCommand = data;
                }, function(){
                    qL.selectedItemsCommand = 'ERROR';
                });
            }else{
                // no command string available
                qL.selectedItemsCommand = [];
            }
        };
        $scope.getMapSymbol = function(item, queryList){
            var qL = queryList;
            var command = item[ queryList.commandColumnKey ];
            if(command){
                ViewPluginService.getMapSymbol(command, $scope.installationId).then(function(data){
                    try{
                        data.r_hierarchy = data.r_hierarchy.split('.');
                        qL.maSymbolParent = data.r_hierarchy[ data.r_hierarchy.length - 1 ];
                        /** @namespace data.r_symbol */
                        qL.mapSymbol = data.r_symbol;
                        qL.mapUrl = $state.href('standAloneMap', {
                            installationId: $scope.installationId,
                            mapId: qL.maSymbolParent
                        });
                        if(qL.mapUrl){
                            qL.mapUrl = qL.mapUrl + '?symbolId=' + qL.mapSymbol;
                        }
                    }catch(e){
                        qL.mapUrl = undefined;
                    }
                }).catch(function(error){
                    $log.error(error);
                    qL.mapUrl = undefined;
                });
            }else{
                qL.mapUrl = undefined;
            }
        };
        $scope.goToMap = function(url, id){
            $window.open(url, '_blank_' + id, 'top=20');
        };
        /**
         * Send command object to gemss
         * @param  {object} item
         * @param  {string} item.r_plantdescription
         * @param  {object} command
         */
        $scope.sendCommand = function(item, command){
            var destinationRole = item[ 'r_command' ].split('|')[ 0 ],
                plantId = item[ 'r_plantid' ];
            if(angular.isDefined(plantId) && destinationRole){
                $log.info('send command', command[ 'r_protocol' ], plantId);
                return ViewPluginService.sendCommand(command[ 'r_protocol' ], plantId, destinationRole, $scope.installationId, { 'PLANT_DESCRIPTION': item.r_plantdescription });
            }else{
                throw new Error('no plantid for item command');
            }
        };

        /**
         * init function
         */
        $scope.populateViewData = function(){
            ViewPluginService.getInstallationViewsCounters($scope.installationId).then(function(counters){
                $scope.queryCounters = {};
                angular.forEach(counters, function(counter){
                    $scope.queryCounters[ counter.viewId ] = counter.count;
                });
            });
            ViewPluginService.getInstallationViewsInfo($scope.installationId).then(function(instViewObj){
                var selectedVIdx = 0;
                $scope.installationInfo = instViewObj.installationInfo;
                $scope.views = instViewObj.views;
                // retrieve selected view index
                for(var i = $scope.views.length - 1; i >= 0; i--){
                    if($scope.isSelectedView($scope.views[ i ])){
                        selectedVIdx = i;
                        break;
                    }
                }
                //Retrieve info of Views/Query for current installation
                if($scope.installationInfo && $scope.views){
                    $scope.currentView = $scope.views[ selectedVIdx ];
                    if($scope.currentView.Queries && $scope.currentView.Queries.length > 0){
                        //Retrieve info and data for each query
                        $scope.currentView.Queries.forEach(function(query, i){
                            query.selectedItems = [];
                            query.selectedItemsCommand = [];
                            // Data grid register API
                            var onRegisterApiF = function(gridApi){
                                $scope.currentView.Queries[ gridApi.grid.options.gemssQueryIndex ].gridApi = gridApi;
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChanged($scope, function(row){
                                    var msg = 'row selected ' + row.isSelected;
                                    $log.debug(msg);
                                    if(row.isSelected){
                                        $scope.onItemSelection(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems[ 0 ] = row.entity;
                                    }else{
                                        $scope.cleanCommandList(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems = [];
                                    }
                                });
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChangedBatch($scope, function(rows){
                                    var row = rows[ 0 ];
                                    if(row.isSelected){
                                        $scope.onItemSelection(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems[ 0 ] = row.entity;
                                    }else{
                                        $scope.cleanCommandList(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems = [];
                                    }

                                });
                                /** @namespace gridApi.core.on */
                                /** @namespace gridApi.core.on.filterChanged */
                                gridApi.core.on.filterChanged($scope, function(){
                                    var grid = this.grid;
                                    $log.debug('filterChanged');
                                    $scope.queries[ grid.options.gemssQueryIndex ].filterTerm = grid.columns[ 1 ].filters[ 0 ].term;
                                });
                            };
                            //Data Grid Options
                            query.gridOptions = {
                                gemssQueryIndex: i,
                                onRegisterApi: onRegisterApiF,
                                enableSelectionBatchEvent: false,
                                enableFiltering: true,
                                enableRowSelection: true,
                                enableSelectAll: false,
                                selectionRowHeaderWidth: 35,
                                rowHeight: 35,
                                showGridFooter: true,
                                columnDefs: [],
                                selectedItems: query.selectedItems,
                                multiSelect: false,
                                enableRowHeaderSelection: true,
                                enableColumnResize: true,
                                init: function(){
                                    $log.debug('NG_GRID INITIALIZE');
                                },
                                filterOptions: {
                                    filterText: '',
                                    useExternalFilter: false
                                },
                                afterSelectionChange: function(){
                                    $log.log('selected data change');
                                },
                                showFilter: true,
                                data: []
                            };
                            query.gridOptions.onRegisterApi = function(gridApi){
                                //set gridApi on scope
                                query.gridApi = gridApi;
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChanged($scope, function(row){
                                    var msg = 'row selected ' + row.isSelected;
                                    $log.log(msg);
                                    if(row.isSelected){
                                        $scope.onItemSelection(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems[ 0 ] = row.entity;
                                    }else{
                                        $scope.cleanCommandList(row.entity, $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ]);
                                        $scope.currentView.Queries[ this.grid.options.gemssQueryIndex ].selectedItems = [];
                                    }
                                });
                                // noinspection JSCheckFunctionSignatures
                                gridApi.selection.on.rowSelectionChangedBatch($scope, function(rows){
                                    var msg = 'rows changed ' + rows.length;
                                    $log.log(msg);
                                });
                            };
                            //Column formatting data grid
                            var createCol = function(colDescription){
                                // noinspection EqualityComparisonWithCoercionJS
                                if(colDescription.Command && colDescription.Command == true){
                                    query.commandColumnKey = colDescription.MappingName;
                                }
                                // noinspection EqualityComparisonWithCoercionJS
                                if(colDescription.Width == 0){
                                    return;
                                }
                                var columnFormat = {};
                                colDescription.MappingName = colDescription.MappingName.toLowerCase();
                                //popolo l'array delle chiavi della query
                                if(angular.isString(colDescription.Key) || colDescription.Key instanceof String){
                                    /** @namespace query.filterKeys */
                                    query.filterKeys.push(colDescription.MappingName);
                                }
                                //Set Sorting Alg for column
                                columnFormat.sortingAlgorithm = function(a, b, rowA){
                                    // noinspection JSUnresolvedFunction
                                    var nulls = rowA.grid.api.core.sortHandleNulls(a, b);
                                    if(nulls !== null){
                                        return nulls;
                                    }else{
                                        if(angular.isString(a)){
                                            if(a > b){
                                                return 1;
                                            }else{
                                                if(a === b){
                                                    return 0;
                                                }else{
                                                    return -1;
                                                }
                                            }
                                        }else{
                                            if(angular.isNumber(a)){
                                                if(a > b){
                                                    return 1;
                                                }else{
                                                    if(a === b){
                                                        return 0;
                                                    }else{
                                                        return -1;
                                                    }
                                                }
                                            }
                                        }

                                    }
                                };
                                //POPOLAMENTO COLONNE
                                columnFormat.field = colDescription.MappingName;
                                // DATE TIME COLUMN FORMAT / formattazione datetime
                                if(colDescription.format && colDescription.format === 'DATETIME'){
                                    columnFormat.cellFilter = 'date:\'dd/MM/yyyy H:mm:ss\'';
                                }
                                /** @namespace colDescription.HeaderText */
                                if(colDescription.HeaderText){
                                    columnFormat.displayName = colDescription.HeaderText;
                                }
                                if(colDescription.Width !== 0){
                                    columnFormat.width = colDescription.Width;
                                }
                                //TODO column sizing
                                query.gridOptions.columnDefs.push(columnFormat);
                            };
                            for(var j in query.Columns){//TODO move to forEach
                                // noinspection JSUnfilteredForInLoop
                                createCol(query.Columns[ j ]);
                            }

                            /*Data Retrieve*/
                            if(query.Data && query.Data.Url){
                                ViewPluginService.getQueryData($scope.installationId, query, UserService.getGroupIdByInstallationId($scope.installationId), $scope.filter.dateTo, $scope.filter.dateFrom,$scope.filter.selectedPlant).then(function(result){
                                    var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
                                    if(query.Data.Url === './query/events'){
                                        query.gridOptions.data = [];
                                        angular.forEach(result, function(item){
                                            if(excludeFilter){
                                                if(!isInSTRlistFilter(item.r_description, excludeFilter) && !isInSTRlistFilter(item.r_info, excludeFilter)){
                                                    query.gridOptions.data.push(item);
                                                }
                                            }else{
                                                query.gridOptions.data.push(item);
                                            }
                                        });
                                    }else{
                                        query.gridOptions.data = result;
                                    }
                                });
                            }
                        });
                    }
                }
                return $http.get(BASE_PATH.API_URL + 'company/' + $scope.installationInfo.customerId);
            }).then(function(response){
                $scope.customerInfo = response.data;
                $scope.subribedTopic = socketService.subscribe('Installation.' + $scope.installationId);
            });
        };
        //Opens New Tab with Cloud90
        $scope.managePlant = function(p){
            window.open(window.location.origin + window.location.pathname + '/#/plant/' + p.MAC, '_blank');
            // $window.location.href = $window.location.hostname + ':9080/#/plant/'+p.MAC;
        };

        var socketListener = $rootScope.$on('socket:refresh', function(){
            ViewPluginService.getInstallationViewsCounters($scope.installationId).then(function(counters){
                $scope.queryCounters = {};
                angular.forEach(counters, function(counter){
                    $scope.queryCounters[ counter.viewId ] = counter.count;
                });
            });
            ViewPluginService.getInstallationViewsInfo($scope.installationId).then(function(instViewObj){
                var viewToUpdate = instViewObj.views.filter(function(item){
                    return item.Id === $scope.selectedView;
                })[ 0 ];
                if(viewToUpdate){
                    angular.forEach(viewToUpdate.Queries, function(query){
                        var previusQueryData = $scope.currentView.Queries.filter(function(quer){
                            return quer.Id === query.Id;
                        })[ 0 ];
                        if(previusQueryData){
                            ViewPluginService.getQueryData($scope.installationId, query, UserService.getGroupIdByInstallationId($scope.installationId)).then(function(result){
                                previusQueryData.gridOptions.data = [];
                                var excludeFilter = UserService.getGroupSettingsByInstallationId($scope.installationId).history_exclude_filter;
                                if(previusQueryData.Data.Url === './query/events'){
                                    angular.forEach(result, function(item){
                                        if(excludeFilter){
                                            if(!isInSTRlistFilter(item.r_description, excludeFilter) && !isInSTRlistFilter(item.r_info, excludeFilter)){
                                                previusQueryData.gridOptions.data.push(item);
                                            }
                                        }else{
                                            previusQueryData.gridOptions.data.push(item);
                                        }
                                    });
                                }else{
                                    previusQueryData.gridOptions.data = result;
                                }
                            });
                        }
                    });

                }
            });

        });
        $scope.editPlant = function(p){
            var modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/plants/editPlant/editPlant.tpl.html',
                controller: 'EditPlantModalCtrl',
                resolve: {
                    plantToEdit: function(){
                        return p;
                    },
                    installationId: function(){
                        return $scope.installationId;
                    }
                }
            });
            modalInstance.result.then(function(selectedItem){
                $log.info(selectedItem);
                $scope.getPlants();
            }, function(result){
                $log.info(result);
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
        /**
         * @param {object} event
         * @param {Array<{Key:string}>} event.Values
         */
        var lowerEventRowFieldsIds = function(event){
            for(var i = event.Values.length - 1; i >= 0; i--){
                event.Values[ i ].Key = event.Values[ i ].Key.toLowerCase();
            }
        };
        /**
         * @param {object} event
         * @param {Array<{ViewId:string|undefined}>} data
         */
        var socketListenerRefresh = $rootScope.$on('socket:view', function(event, data){
            var j;
            try{
                var toRemoveItemList, toRemoveItem, removeIndex, qId, queryData;
                var selectedQuery = undefined;
                var expression = {};
                $log.debug('incoming message from ws service', data);
                // noinspection EqualityComparisonWithCoercionJS
                // noinspection EqualityComparisonWithCoercionJS
                if(!data.installationId || data.installationId != $scope.installationId){
                    $log.error('Rejected message from ws service, no match with current installation', data);
                    return;
                }
                data.data.forEach(function(eventData){
                    $log.debug('update event : ', eventData);
                    /** @namespace eventData.ViewId */
                    if(!eventData.ViewId && !eventData.Type){
                        return;
                    }
                    // Find Counter to be updated
                    var toUpdateView = $filter('filter')($scope.views, { 'Id': eventData.ViewId }, true)[ 0 ];
                    if(toUpdateView){
                        /** @namespace eventData.QueryId */
                        /** @namespace toUpdateView.Queries */
                        var toUpdateQuery = $filter('filter')(toUpdateView.Queries, { 'Id': eventData.QueryId }, true)[ 0 ];
                        /** @namespace toUpdateQuery.Counter */
                        if(toUpdateQuery && toUpdateQuery.Counter){
                            switch(eventData.Type){
                                case 'ADD':
                                    $scope.queryCounters[ eventData.ViewId ] = $scope.queryCounters[ eventData.ViewId ] + 1;
                                    break;
                                case 'REMOVE':
                                    $scope.queryCounters[ eventData.ViewId ] = $scope.queryCounters[ eventData.ViewId ] - 1;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    //  Current Queries update
                    if(eventData.ViewId === $scope.currentView.Id){
                        qId = eventData.QueryId;
                        for(i = $scope.currentView.Queries.length - 1; i >= 0; i--){
                            // noinspection EqualityComparisonWithCoercionJS
                            if($scope.currentView.Queries[ i ].Id == qId){
                                selectedQuery = $scope.currentView.Queries[ i ];
                                break;
                            }
                        }
                        if(angular.isUndefined(selectedQuery) || angular.isUndefined(selectedQuery.gridOptions) || angular.isUndefined(selectedQuery.gridOptions.data)){
                            return;
                        }
                        queryData = selectedQuery.gridOptions.data;
                        lowerEventRowFieldsIds(eventData);
                        var objToAdd = {};
                        var matchItems;
                        switch(eventData.Type){
                            case 'ADD':
                                try{
                                    if(!eventData.Values){
                                        $log.error('update request with no data');
                                        throw 'update request with no data';
                                    }
                                    //TODO convert to reduce function
                                    for(j in eventData.Values){
                                        // noinspection JSUnfilteredForInLoop
                                        objToAdd[ eventData.Values[ j ].Key ] = eventData.Values[ j ].Value;
                                    }
                                    /*Find element if exists */
                                    /** @namespace selectedQuery.Columns */
                                    for(i in selectedQuery.Columns){
                                        // noinspection JSUnfilteredForInLoop
                                        if(selectedQuery.Columns[ i ].Key){
                                            // noinspection JSUnfilteredForInLoop
                                            $log.debug(selectedQuery.Columns[ i ].Key, selectedQuery.Columns[ i ]);
                                            // noinspection JSUnfilteredForInLoop
                                            /** @namespace eventData.RowKey */
                                            expression[ selectedQuery.Columns[ i ].MappingName ] = eventData.RowKey.toLowerCase();
                                            break;
                                        }
                                    }
                                    toRemoveItemList = $filter('filter')(queryData, expression, true);
                                    /* replace or insert*/
                                    matchItems = toRemoveItemList.length;
                                    if(matchItems !== 0){
                                        toRemoveItem = toRemoveItemList[ 0 ];
                                        removeIndex = queryData.indexOf(toRemoveItem);
                                        queryData[ removeIndex ] = objToAdd;
                                    }else{
                                        if(matchItems > 1){
                                            //FIXME
                                            throw 'multiple matching';
                                        }else{
                                            queryData.push(objToAdd);
                                        }
                                    }
                                }catch(error){
                                    $log.error(error);
                                }
                                break;
                            case 'UPDATE':
                                try{
                                    if(!eventData.Values){
                                        $log.error('update request with no data');
                                        throw 'update request with no data';
                                    }
                                    for(j in eventData.Values){
                                        // noinspection JSUnfilteredForInLoop
                                        objToAdd[ eventData.Values[ j ].Key ] = eventData.Values[ j ].Value;
                                    }
                                    //Find element if exists
                                    for(i in selectedQuery.Columns){
                                        // noinspection JSUnfilteredForInLoop
                                        if(selectedQuery.Columns[ i ].Key){
                                            // noinspection JSUnfilteredForInLoop
                                            $log.debug(selectedQuery.Columns[ i ].Key, selectedQuery.Columns[ i ]);
                                            // noinspection JSUnfilteredForInLoop
                                            expression[ selectedQuery.Columns[ i ].MappingName ] = eventData.RowKey.toLowerCase();
                                            break;
                                        }
                                    }
                                    toRemoveItemList = $filter('filter')(queryData, expression, true);
                                    //replace or insert
                                    matchItems = toRemoveItemList.length;
                                    if(matchItems !== 0){
                                        toRemoveItem = toRemoveItemList[ 0 ];
                                        // if updating element is selected and has commands array available, clean it all
                                        if(selectedQuery.selectedItems.indexOf(toRemoveItem) !== -1){
                                            selectedQuery.gridApi.selection.clearSelectedRows();
                                        }
                                        removeIndex = queryData.indexOf(toRemoveItem);
                                        queryData[ removeIndex ] = objToAdd;
                                    }else{
                                        if(matchItems > 1){
                                            throw 'multiple matching';
                                        }else{
                                            queryData.push(objToAdd);
                                        }
                                    }
                                }catch(error){
                                    $log.error(error);
                                }
                                break;
                            case 'REMOVE':
                                //Find column key and push to search expression
                                try{
                                    for(var i in selectedQuery.Columns){
                                        // noinspection JSUnfilteredForInLoop
                                        if(selectedQuery.Columns[ i ].Key){
                                            // noinspection JSUnfilteredForInLoop
                                            $log.debug(selectedQuery.Columns[ i ].Key, selectedQuery.Columns[ i ]);
                                            // noinspection JSUnfilteredForInLoop
                                            expression[ selectedQuery.Columns[ i ].MappingName ] = eventData.RowKey.toLowerCase();
                                            break;
                                        }
                                    }
                                    toRemoveItemList = $filter('filter')(queryData, expression, true);
                                    if(toRemoveItemList.length === 0){
                                        $log.error('no query item matching with request: ', queryData);
                                        throw 'no query matching';
                                    }
                                    toRemoveItem = toRemoveItemList[ 0 ];
                                    // if updating element is selected and has commands array available, clean it all
                                    if(selectedQuery.selectedItems.indexOf(toRemoveItem) !== -1){
                                        selectedQuery.gridApi.selection.clearSelectedRows();
                                    }
                                    removeIndex = queryData.indexOf(toRemoveItem);
                                    queryData.splice(removeIndex, 1);
                                }catch(error){
                                    $log.error(error);
                                }
                                break;
                        }
                    }
                });
            }catch(err){
                //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
            }
            $scope.$apply();
        });
        // Unregister listeners
        $scope.$on('$destroy', function(){
            $log.log('Unregistering listener');
            socketService.unsubscribe($scope.subribedTopic);
            socketListener();
            socketListenerRefresh();
        });
        $scope.populateViewData();
    });

