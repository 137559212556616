angular.module('shared')
  .filter('mediaPath', function(BASE_PATH) {
    return function(input) {
      if (angular.isUndefined(input)||input===null){
        return BASE_PATH.MEDIA_PATH +'image/';
      }else{
        return input;
      }
    };
  });
