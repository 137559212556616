/* eslint-disable angular/controller-as */

angular.module('plants')
  .controller('PlantsManagementCtrl', function ($uibModal, $log, $scope, modalService, installationId, socketService, $rootScope,$state,PermPermissionMap,PermAuthorization,PlantService,Installation,Company,BASE_PATH,UserService) {
    $scope.installationId = installationId;
    $scope.managePlantPermissionMap=new PermPermissionMap({only:'BUTTON-IMPIANTI-MANUTENZIONE'});
    var sub = socketService.subscribe('Installation.'+$scope.installationId);
    /**
     *
     * @param {object} event
     * @param {object} data
     * @param {object} data.MSG
     * @param {string} data.MSG.idPlant
     */

    $scope.TEBE_SMALL_URL=BASE_PATH.TEBE_SMALL_URL;
    var com90Socket = $rootScope.$on('socket:com90', function (event, data) {
      $log.debug('controller Received Feedback');
      if (data.MSG && data.MSG.evntType ){
        if (data.MSG.idPlant){
          var idPlant = data.MSG.idPlant;
          $scope.plantList.find(function(p){
            return p.MAC.toLowerCase() === idPlant.toLowerCase();
          }).state = data.MSG.state;
        }
      }
      $scope.$apply();
    });
    $scope.getPlants = function(){
      PlantService.plantListByInstallationId($scope.installationId).then(function(data){
        $scope.plantList = data.result;
        $scope.token=data.tebesmallToken;
      });
    };
    $scope.getInstallationInfo = function(){
      Installation.get({idInstallation:$scope.installationId}).$promise.then(function(data){
        $scope.installationInfo = data[0];
        return Company.get({companyId:$scope.installationInfo.customerId}).$promise;
      }).then(function(response){
        $scope.customerInfo = response.data;
      });
    };
    $scope.managePlant = function(p,event){
      if(event){
        event.stopPropagation();
      }
      PermAuthorization.authorizeByPermissionMap($scope.managePlantPermissionMap).then(function(){
        $state.go('plant',{plantId:p.MAC,installationId:$scope.installationId});//TODO fix opening an other page
      }).catch(function () {
        //permission denied
      });

    };
    $scope.getPlants();
    $scope.getInstallationInfo();
    $scope.viewCustomer = function(customer){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/customer/viewCustomer/viewCustomer.tpl.html',
        controller: 'ViewCustomerModalCtrl',
        resolve: {
          customerToView: function () {
            return  customer;
          },
          type : function() {
            return 'customer';
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getCustomers();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
    $scope.plantUploaderOpen = function(p,event) {
      if (event){
        event.stopPropagation();
      }
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/plants/plantUploaderOpen/plantUploaderOpen.tpl.html',
        controller: 'PlantConfigUploadCtrl',
        resolve: {
          installationId: function () {
            return $scope.installationId;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getInstallations();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });

    };
    $scope.editPlant = function(p,event){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/plants/editPlant/editPlant.tpl.html',
        controller: 'EditPlantModalCtrl',
        resolve: {
          plantToEdit: function () {
            return  p;
          },
          installationId : function() {
            return $scope.installationId;
          }
        }
      });
      if(event){
        event.stopPropagation();
      }
      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getPlants();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
    $scope.$on('$destroy',function (){
      socketService.unsubscribe(sub);
      com90Socket();
    });
  });
