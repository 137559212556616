/* eslint-disable angular/controller-as, angular/no-services*/

angular.module('customer')
  .controller('CustomersCtrl', function ($uibModal, $log, $scope, BASE_PATH, $http, $location, modalService, UserService) {
    $scope.currentCompany = UserService.getUserCompanyDescription();
    $scope.getCustomers = function(){
      $http.get(BASE_PATH.API_URL+'company/'+UserService.getUserManagerCompanyId()+'/customer').then(function(data){
        $scope.customers = data.data;
      });
    };
    $scope.getCustomers();
    $scope.viewInstallations = function(c){
      $location.path('installations');
      $location.search('company',c.customerId);
    };
    $scope.openInstallation = function(inst){
      $location.path('plantsmanagement/'+inst.installationId);
    };
    $scope.deleteCustomer = function(customerId){
      return $http.delete(BASE_PATH.API_URL+'company/'+customerId);
    };
    $scope.editCustomer = function(customer){
        var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/customer/editCustomer/editCustomer.html',
        controller: 'EditCustomerModalCtrl',
        resolve: {
          customerToEdit: function () {
            return  customer;
          },
          type : function() {
            return 'customer';
          },
          deleteCustomerFn : function(){
            return $scope.deleteCustomer;
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getCustomers();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
    $scope.newCustomer = function(){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/customer/newCustomer/newCustomer.tpl.html',
        controller: 'NewCustomerModalCtrl',
        resolve: {
          type : function() {
            return 'customer';
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getCustomers();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
  });
