/* eslint-disable angular/log,angular/on-watch */


angular.module('app', ['ui.router', 'ngHttpStatus', 'ngConstants', 'installations', 'ui.bootstrap', 'shared', 'managers', 'users', 'plants', 'maps', 'dashboard', 'ngResource', 'blockUI', 'scheduler', 'tmh.dynamicLocale']);
(function () {
  //loadingInfo
  var loadingScreen = pleaseWait({
    logo: '/images/logo-saet.png',
    backgroundColor: '#EBE5Df',
    loadingHtml: '<div class="sk-folding-cube"><div class="sk-cube1 sk-cube"></div><div class="sk-cube2 sk-cube"></div><div class="sk-cube4 sk-cube"></div><div class="sk-cube3 sk-cube"></div></div><div> Benvenuto in BMS Cloud Management </div>'
  });
  //retreave user info before bootstrap angular app
  var initInjector = angular.injector(['ng']);
  var $http = initInjector.get('$http');
  var $log = initInjector.get('$log');
  var $window = initInjector.get('$window');
  var module = angular.module('app');
  initInjector = angular.injector(['ngHttpStatus']);
  var HttpCodes = initInjector.get('HttpCodes');
  initInjector = angular.injector(['ngConstants']);
  var BASE_PATH = (initInjector.get('BASE_PATH')||{}).BACKEND_BASE_URL;

  function getParameterByName(name, url) {
    if (!url) {
      url = $window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // noinspection JSCheckFunctionSignatures
  angular.element(document).ready(function () {
    fetchData().then(bootstrapApplication, function (errorData) {
      if (errorData.status === HttpCodes.FORBIDDEN) {
        $log.debug('Error in security validation for current user. message : %s - Token %s ', errorData.data.message, errorData.config.headers['x-access-token']);
        console.error(errorData.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href));
        $window.location.href = errorData.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
      } else {
        $log.debug('Error in security validation. Code: %s', errorData.status);
        //FIXME insert error message
        $window.location.href = errorData.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
      }
    });
  });


  function fetchData() {
	//Check querystring passed token
    var token = getParameterByName('token');
    $log.info(' ', token);
    if (token) {
      $log.info('Found token in url. Setting into ');
      var tokenRegex = new RegExp('[?&]token(=([^&#]*)|&|#|$)');
      localStorage.setItem('ba_t', token);
      var redirectUrl = $window.location.href.replace(tokenRegex.exec($window.location.href)[0], '');
      $log.info('Redirect browser to %s', redirectUrl);
      location.href = redirectUrl;
    }
    $log.debug('Make Security Call For Current User');
    var config = {
      headers: {
        'x-access-token': localStorage.ba_t
      }
    };

    // FIXME
    var apiPath = BASE_PATH + '/api/userinfo';
    return $http.get(apiPath, config).then(function (data) {
      $log.debug('Security Check for token OK, inserting into angular costant');
      module.constant('userInfo', data.data);
      module.constant('LOGOUT_URL', data.logoutUrl);
      try {
        $window.loading_screen.finish();
      } catch (err) {
        $log.debug('window.loading_screen not available. Passing out.');
      }
    });
  }

  /**
   * Manually Bootstrap Angular
   * @return {[type]} [description]
   */
  function bootstrapApplication() {
    // noinspection JSCheckFunctionSignatures
    angular.bootstrap(document, ['app']);
    loadingScreen.finish();
  }
}());


angular.module('app')
  .config(function ( $httpProvider, $urlRouterProvider, $resourceProvider, blockUIConfig, tmhDynamicLocaleProvider) {

    tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
    tmhDynamicLocaleProvider.defaultLocale('it');
    // Change the default overlay message
    blockUIConfig.message = 'Attendere prego';
    blockUIConfig.autoBlock = false;
    $urlRouterProvider.otherwise('/installations');
    var initInjector = angular.injector(['ngHttpStatus']);
    var HttpCodes = initInjector.get('HttpCodes');
    $httpProvider.interceptors.push(function ($q,$window) {
      return {
        request: function (config) {
          config.headers['x-access-token'] = localStorage.ba_t;
          config.headers['Accept'] = 'application/json;';
          return config;
        },
        response: function (res) {
          if (res.status === HttpCodes.FOUND) {
            $window.location.href = rejection.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
          }
          return res;
        },
        responseError: function (rejection) {
          if (rejection.status === HttpCodes.FORBIDDEN) {
            if (rejection.data) {
              $window.location.href = rejection.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
            }
          } else {
            if (rejection.status === HttpCodes.FOUND) {
              $window.location.href = rejection.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
            }
          }
          return $q.reject(rejection);
        }
      };
    });
  });

//method includes from Polyfill
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';
    // eslint-disable-next-line angular/typecheck-number
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}


