/* eslint-disable angular/controller-as */
angular.module('maps')
    .controller('MapsCtrl', function ($q, MapService, $rootScope, $log, $scope, socketService, ViewPluginService, DynamicColorService, UserService, installationId, mapId,showNavigator) {
        // contains all tree nodes flattened and accessible by id
        $scope.breadcrumbsPath = [];
        $scope.treeNodesMap = {};
        // array with expanded nodes
        $scope.expandedNodes = [];
        $scope.showNavigator=showNavigator;
        $scope.getTreeNodeVisibility = function (node) {
            return MapService.getTreeVisibility(node);
        };
        $scope.calculateColor = DynamicColorService.getColorForMap;

        function formatNodes(treeData, parent) {
            return treeData.map(function (obj) {
                var rObj = obj;
                rObj.name = obj.r_symbolconfiguration.description;
                rObj.parent = parent;
                rObj.children = [];
                $scope.treeNodesMap[obj.r_idsymbol] = rObj;
                return rObj;
            });
        }

        $scope.expandNodeEvent = function (node, expanded) {
            $log.info(node, expanded);
            if (expanded === true && node.children.length === 0) {
                node.children = [{name: 'loading'}];
                MapService.map.getChildren($scope.installationId, node.r_idsymbol)
                    .then(function (children) {
                        node.children = formatNodes(children, node);
                    });
            }
        };
        $scope.expandNode = function (node) {
            var matchingIdx = [];
            $scope.expandedNodes.forEach(function (x, idx) {
                if (x.r_idsymbol === node.r_idsymbol) {
                    matchingIdx.push(idx);
                }
            });
            if (matchingIdx.length !== 0) {
                for (var i = 0; i < matchingIdx.length; i++) {
                    $scope.expandedNodes.splice(matchingIdx[i], 1);
                }
            } else {
                $scope.expandedNodes.push(node);
            }
        };
        $scope.onBreadcrumbSelection = function (node) {
            $scope.showSelected(node);
        };
        $scope.isNodeSelected = function (node) {
            if ($scope.selectedNode) {
                return node.r_idsymbol === $scope.selectedNode.r_idsymbol;
            }
            return false;
        };
        /**
         * Show selected node by changing mapNode and opens parent leafs
         * @param  {object} node
         * @param  {object} node.parent
         * @param  {object} node.r_idsymbol
         * @param  {Array<object>} node.children
         */
        $scope.showSelected = function (node) {
            $scope.selectedNode = $scope.treeNodesMap[node.r_idsymbol];
            if (node.r_symbolconfiguration.backGroundFileId) {
                MapService.map.getNodeSymbols($scope.installationId, node.r_idsymbol)
                    .then(function (expandedNode) {
                        $scope.breadcrumbsPath = [node];
                        node.children = formatNodes(expandedNode.children, node);
                        $scope.mapNode = node;
                        try {
                            if (node.parent === null) {
                                $log.debug('no parent for node ', node);
                            } else {
                                expandParentTreeNodes(node.parent.r_idsymbol);
                            }
                        } catch (err) {
                            $log.error(err);
                        }
                        $rootScope.$emit('map:resize');
                    });
            } else {
                $rootScope.$emit('map:selectnode', node);
                throw new Error('no background for tree element');
            }
        };
        /**
         * Recursive expanding of parents tree-node
         * @param  {string} parentId
         */
        var expandParentTreeNodes = function (parentId) {
            var parent = $scope.treeNodesMap[parentId];
            $scope.breadcrumbsPath.push(parent);
            if ($scope.expandedNodes.indexOf(parent) === -1) {
                $scope.expandedNodes.push(parent);
            }
            if (angular.isDefined(parent.parent) && parent.parent !== null && angular.isDefined(parent.parent.r_idsymbol) && parent.parent.r_idsymbol !== null) {
                $log.info('expand parent');
                expandParentTreeNodes(parent.parent.r_idsymbol);
            }
        };
        // INITIALIZATION
        $scope.init = function () {
            $scope.installationId = installationId;
            $scope.routeSelectedMapId = mapId;
            $scope.rootMapId = $scope.routeSelectedMapId || UserService.getInitialMapByInstallationId($scope.installationId);
            MapService.map.getNodeSymbols($scope.installationId, $scope.rootMapId).then(function (rootNode) {
                $log.debug('end map tree');
                $scope.rootData = rootNode;
                $scope.treeNodesMap[$scope.rootMapId] = rootNode;
                rootNode.children = formatNodes(rootNode.children, rootNode);
                $scope.dataForTheTree = [rootNode];
                $scope.expandedNodes.push($scope.dataForTheTree[0]);
                $scope.showSelected($scope.dataForTheTree[0]);
                $scope.subcribedTopic = socketService.subscribe('Installation.' + $scope.installationId);
            });

            /**
             * fetch, expand tree and select passed node
             * @param {*} destinationNode
             */
            function navigateToNode(destinationNode) {
                $log.log('navigation event received', destinationNode);
                var nextMapNode = $scope.treeNodesMap[destinationNode.nodeId];
                // if node to navigate exists
                if (angular.isDefined(nextMapNode)) {
                    $scope.showSelected(nextMapNode);
                    $scope.$apply();
                    // if node does not exists, fetch children data of parents, then show
                } else {
                    var hierarchy = destinationNode.hierarchy;
                    if (hierarchy) {
                        var hierarchy_array = hierarchy.split('.');
                        var nodesToBeFetched = [];
                        for (var i in hierarchy_array) { // get nodes that are not into tree nodes map
                            // noinspection JSUnfilteredForInLoop
                            var nodeId = hierarchy_array[i];
                            var node = $scope.treeNodesMap[nodeId];
                            if (angular.isDefined(node)) {
                                nodesToBeFetched.push(hierarchy_array[i - 1]);
                            }
                        }
                        var p = $q.when(''); //create 'empty' promise which resolves auto-magically
                        //recursively reducing nodesToBeFetched array, which implies getting node symbols - initialize reduce function with empty response in order to get it working
                        nodesToBeFetched.reduce(function (prev, mapId) {
                            return prev.then(function () {
                                return MapService.map.getNodeSymbols($scope.installationId, mapId);
                            }).then(function (rootNode) {
                                // associate only fetched children with current parent node
                                $scope.treeNodesMap[mapId].children = formatNodes(rootNode.children, $scope.treeNodesMap[mapId]);
                            });
                        }, p)
                            .then(function () {// after reduce function because it returns a promise object
                                var nextMapNode = $scope.treeNodesMap[destinationNode.nodeId];
                                $scope.showSelected(nextMapNode);
                            });
                    } else {
                        toastr.error('no node data');
                    }
                }
            }

            /**
             * Map 'navigateTo' event listener
             */
            $scope.navigateToListener = $rootScope.$on('map:navigate', function (event, destinationNode) {
                navigateToNode(destinationNode);
            });
            /**
             * Update tree states on events
             */
            $scope.socketListener = $rootScope.$on('socket:map', function (event, payload) {
                $log.info('mapEvent received', payload);
                // noinspection JSUnresolvedVariable
                var symbolsData = payload.data || [];
                for (var i = 0; i < symbolsData.length; i++) {
                    var variationSymbol = symbolsData[i];
                    // noinspection JSUnresolvedVariable
                    var toUpdateSymbol = $scope.treeNodesMap[variationSymbol.IdSymbol];
                    if (angular.isDefined(toUpdateSymbol)) {
                        $log.debug('updating symbol state', toUpdateSymbol);
                        toUpdateSymbol.r_state = variationSymbol.State;
                        toUpdateSymbol.r_command = variationSymbol.Command;
                    }
                }
                $scope.$apply();
            });
        };
        $scope.init();
        // Unregister
        $scope.$on('$destroy', function () {
            $log.log('Unregistering listener');
            $scope.socketListener();
            $scope.navigateToListener();
            socketService.unsubscribe($scope.subcribedTopic);
        });
    });
