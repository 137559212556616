/* eslint-disable */
angular.module('dashboard')
    .controller('dashboardInstallationCtrl', function($state, DashboardService, DynamicColorService, $log, $scope, installationId, socketService, $rootScope, $window, UserService){
        $scope.getColorForItem = DynamicColorService.getColorForItem;
        var _openDetailPage = function(symbol){
            var url;
            if(angular.isUndefined(symbol.r_symbolconfiguration.$type)){//views
                //url=$state.href('standAloneView', {installationId: $scope.installationId,viewId:symbol.r_symbolconfiguration.Id});
                url = $state.href('standAloneView', {
                    installationId: $scope.installationId,
                    viewId: symbol.r_symbolconfiguration.Id
                });
            }else{
                url = $state.href('standAloneMap', { installationId: $scope.installationId, mapId: symbol.r_idsymbol });
            }
            if(url){
                $window.open(url, '_blank', "top=20");
            }else{
                $log.error('no page found');
            }
        };
        var _openInSamePage = function(symbol){
            if(angular.isUndefined(symbol.r_symbolconfiguration.$type)){//views
                $scope.selectedMap = undefined;
                $scope.selectedView = symbol.r_symbolconfiguration.Id;
            }else{
                $scope.selectedView = undefined;
                $scope.selectedMap = symbol.r_idsymbol;//TODO complete
            }
        };
        var _updateState = function(){
            DashboardService.getSymbolsWithCounterForMaps($scope.installationId).then(function(response){
                angular.forEach(response, function(symbol){
                    if(angular.isUndefined(symbol.r_symbolconfiguration.$type) || symbol.r_symbolconfiguration.$type === null){
                        //views
                        $scope.views[ symbol.r_symbolconfiguration.Id ].counter = symbol.counter;
                    }else{
                        //maps
                        $scope.maps[ symbol.r_idsymbol ].r_state = symbol.r_state;
                    }
                });
            }).catch(function(error){
                $log.error(error);
            });
        };
        $scope.openDetail = function(symbol){
            if($scope.sameWindow){
                _openInSamePage(symbol);
            }else{
                _openDetailPage(symbol);
            }
            _updateState()
        };
        var viewUpdateEventHandler = function(event, jsData){
            $log.debug('incoming message from ws service', jsData);
            $scope.$apply(function(){
                try{
                    // noinspection EqualityComparisonWithCoercionJS
                    if(!jsData.installationId || jsData.installationId != $scope.installationId){
                        $log.error('Rejected message from ws service, no match with current installation', jsData);
                        return;
                    }
                    var eventsData = jsData.data;
                    eventsData.forEach(function(eventData){
                        if(!eventData.ViewId || !eventData.Type){
                            return;
                        }

                        /**
                         * Find view counter to be updated
                         */
                        if($scope.views[ eventData.ViewId ] && $scope.views[ eventData.ViewId ].CounterQueryId === eventData.QueryId && angular.isDefined($scope.views[ eventData.ViewId ].counter)){
                            switch(eventData.Type){
                                case 'ADD':
                                    $scope.views[ eventData.ViewId ].counter = $scope.views[ eventData.ViewId ].counter + 1;
                                    break;
                                case 'REMOVE':
                                    $scope.views[ eventData.ViewId ].counter = $scope.views[ eventData.ViewId ].counter - 1;
                                    break;
                                default:
                                    $log.debug("no event");
                                    break;
                            }
                        }
                    });
                }catch(err){
                    $log.error(err);
                    //$scope.eventList.push({ tokens: ['MSG_DECODE_ERR', data]})
                }
            });
        };
        var socketListenerView = $rootScope.$on('socket:view', viewUpdateEventHandler);
        var socketListenerRefresh = $rootScope.$on('socket:refresh', _updateState);
        var socketListenerMap = $rootScope.$on('socket:map', function(event, payload){
            $scope.$apply(function(){
                // noinspection JSUnresolvedVariable
                var symbolsData = payload.data;
                for(var i = 0; i < symbolsData.length; i++){
                    var variationSymbol = symbolsData[ i ];
                    // noinspection JSUnresolvedVariable
                    if($scope.maps[ variationSymbol.IdSymbol ] !== undefined){
                        // noinspection JSUnresolvedVariable
                        $log.debug('updating symbol state', $scope.maps[ variationSymbol.IdSymbol ]);
                        // noinspection JSUnresolvedVariable
                        $scope.maps[ variationSymbol.IdSymbol ].r_state = variationSymbol.State;
                        // noinspection JSUnresolvedVariable
                        $scope.maps[ variationSymbol.IdSymbol ].r_command = variationSymbol.Command;
                    }
                }
            });
            $scope.$apply();
        });
        $scope.setButtonWidth = function(installationId){
            var groupSettings = UserService.getGroupSettingsByInstallationId(installationId);
            $scope.numberOfButtons = groupSettings.LG || 10;
            var width = $window.innerWidth;
            if(width < 1200 && width >= 992){
                $scope.numberOfButtons = $scope.groupSettings.MD || 10;
            }
            if(width < 992){
                $scope.numberOfButtons = $scope.groupSettings.XS || 10;
            }
            console.log($scope);
            $scope.buttonWidth = Math.floor(($window.innerWidth - 30) / $scope.numberOfButtons) + 'px';
        };

        function _init(){
            $scope.installationId = installationId;
            $scope.selectedView = undefined; //not needed but usefull for testing
            $scope.groupSettings = UserService.getGroupSettingsByInstallationId(installationId);
            $scope.sameWindow = $scope.groupSettings.singleWindow || false; //TODO handle with user preference
            $scope.setButtonWidth(installationId);
            DashboardService.getSymbolsWithCounterForMaps($scope.installationId).then(function(response){
                $scope.symbols = response;
                $scope.views = $scope.symbols.filter(function(item){
                    return angular.isUndefined(item.r_symbolconfiguration.$type) || item.r_symbolconfiguration.$type === null;
                }).reduce(function(views, item){
                    views[ item.r_symbolconfiguration.Id ] = item;
                    return views;
                }, {});
                $scope.maps = $scope.symbols.filter(function(item){
                    return angular.isDefined(item.r_symbolconfiguration.$type) && item.r_symbolconfiguration.$type === "SMOWKE.Library.NodeSymbol, SMOWKE.Library";//TODO use constant
                }).reduce(function(maps, item){
                    maps[ item.r_idsymbol ] = item;
                    return maps;
                }, {});
                $scope.subribedTopic = socketService.subscribe('Installation.' + $scope.installationId);
            }).catch(function(error){
                $log.error(error);
            });
        }

        window.onresize = function(){
            $scope.setButtonWidth(installationId)
        };
        _init();
        $scope.$on('$destroy', function(){
            socketListenerView();
            socketListenerMap();
            socketListenerRefresh();
            socketService.unsubscribe($scope.subribedTopic);
        });
    });
