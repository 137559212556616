angular.module('maps')
    .service('MapService', function(BASE_PATH, UserService, $q, $interval, $http){
        // AngularJS will instantiate a singleton by calling "new" on this function
        var _getNodeChildren = function(installationId, nodeId){
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId + '/maps/tree/' + nodeId).then(function(result){
                return result.data;
            });
        };
        var _nodeIsMap = function(node){
            return angular.isDefined(node.r_symbolconfiguration.backGroundFileId);
        };
        var _getTreeVisibility = function(node){
            if(node.r_symbolconfiguration.$type.indexOf('Antintrusion') !== -1){
                return true;
            }else{
                return node.r_symbolconfiguration.$type.indexOf('Library.NodeSymbol') !== -1;
            }
        };
        var _getNode=function(installationId,nodeId){
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId + '/maps/node/' + nodeId).then(function(response){
                return response.data[0];
            });
        };
        var _getNodeForInstallation = function(installationId, nodeId){
            var rootNode = {
                name: '',//$scope.rootData[0].symbolconfiguration.label,
                children: [],
                parent: null,
                r_command: null,
                r_idplant: null,
                r_idsymbol: nodeId,
                r_state: 'ERROR',
                r_symbolconfiguration: undefined
            };
            return _getNode(installationId, nodeId).then(function(result){
                rootNode.r_symbolconfiguration = result.r_nodeconfiguration;
                rootNode.r_state = result.r_state;
                rootNode.name = rootNode.r_symbolconfiguration.description;
                return _getNodeChildren(installationId, nodeId);
            }).then(function(result){
                rootNode.children = result.filter(function(item){
                    return item.r_symbolconfiguration.visible === true;//TODO is better to put in the backend (user experience reason)
                });
                return rootNode;
            });
        };
        var _getChildrenOfTypeMaps=function(installationId,nodeId){
            return _getNodeChildren(installationId,nodeId).then(function(result){
                return result.filter(function(node){
                    return _nodeIsMap(node);
                });
            });
        };
        return {
            map: {
                getNodeSymbols: _getNodeForInstallation,
                getChildren: _getNodeChildren,
                getChildrenOfTypeMaps:_getChildrenOfTypeMaps,
                getNodeInfo:_getNode
            },
            getTreeVisibility: _getTreeVisibility
        };
        
    });
