angular.module('auth')
    .component('groupSettings', {
        bindings: {
            group: '=',
            onUpdate: '&',
            rootMapSymbol: '=',
            installationId: '='
        },
        controller: function ($log, HOME_PAGE_OPTIONS, MapService) {
            var $ctrl = this;
            $ctrl.homePageOptions = HOME_PAGE_OPTIONS;
            $ctrl.saveGroup = function () {
                $ctrl.onUpdate();
            };
            $ctrl.$onInit = function () {
                $ctrl.changeInitialMap = false;
            };
            $ctrl.loadChildFn = function (nodeId) {
                return MapService.map.getChildrenOfTypeMaps($ctrl.installationId, nodeId);
            };
            $ctrl.initialMapChanged = function (node) {
                $ctrl.group.groupSettings.initialMapNode = node;
                $ctrl.group.groupSettings.initialMapNode.r_nodeconfiguration = $ctrl.group.groupSettings.initialMapNode.r_symbolconfiguration;
                $ctrl.group.groupSettings.initialMapSymbol = node.r_idsymbol;﻿
                $ctrl.changeInitialMap = false;
            };
        },
        templateUrl: 'app/auth/groups/groupDetail/settings/settings.tpl.html'
    });

