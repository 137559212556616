angular.module('plants',['shared','customer','ui.router', 'permission', 'permission.ui','auth'])
  .config(function($stateProvider) {
    // eslint-disable-next-line angular/controller-as-route
    $stateProvider
      .state('plants', {
        url:'/plantsmanagement/:installationId',
        parent:'withHeader',
        controller: 'PlantsManagementCtrl',
        templateUrl: 'app/plants/plants/plantsManagement.tpl.html',
        resolve: {
          installationId:function($stateParams){
            return $stateParams.installationId;
          },
          sock: function ($stateParams, socketService) {
            return socketService.connect($stateParams);
          }
        },
        data: {
          permissions: {
            only: ['BUTTON-INSTALLAZIONI-VISUALIZZA_IMPIANTI'],
            redirectTo: 'installations'
          }
        }
      }).state('plant', {
        url:'/plantsmanagement/:installationId/plant/:plantId',
        parent:'withHeader',
        controller: 'PlantConsoleCtrl',
        templateUrl: 'app/plants/plantConsole/plantConsole.tpl.html',
        resolve: {
          plantId:function($stateParams){
            return $stateParams.plantId;
          },
          installationId:function($stateParams){
            return $stateParams.installationId;
          },
          sock: function ($stateParams, socketService) {
            return socketService.connect($stateParams);
          }
        },
        data: {
          permissions: {
            only: ['BUTTON-IMPIANTI-MANUTENZIONE'],
            redirectTo: 'installations'
          }
        }
      });
  });
