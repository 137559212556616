angular.module('users')
    .component('users',{
        bindings: {
            currentCompany: '=',
            employees:'='
            
        },
   controller:function(UserService,$uibModal,Company,blockUI,$log){
       var $ctrl = this;
       $ctrl.deleteEmployee = function(emp){
           var modalOptions = {
               closeButtonText: 'Annulla',
               actionButtonText: 'Sì',
               headerText: 'Eliminare ?',
               bodyText: 'Sicuro di volere eliminare questo utente?'
           };
           var modalInstance = $uibModal.open({
               ariaLabelledBy: 'modal-title',
               ariaDescribedBy: 'modal-body',
               templateUrl: 'app/shared/popup/confirmModal/confirmModal.tpl.html',
               controller: 'ConfirmModalInstanceCtrl',
               resolve: {
                   message: function () {
                       return  modalOptions;
                   }
               }
           });
           modalInstance.result.then(function () {
               blockUI.start();
               UserService.deleteUser(emp.userId).then(function(){
                   Company.getEmployee(UserService.getUserManagerCompanyId()).then(function (employees) {
                       $ctrl.employees=employees;
                       blockUI.stop();
                   });
               }).catch(function (error) {
                   toastr.error(error);
               blockUI.stop();
               });
           });
       };
       $ctrl.getEmployees=function(){
           Company.getEmployee(UserService.getUserManagerCompanyId());
       };
       $ctrl.editUser = function(user){
           var modalInstance = $uibModal.open({
               ariaLabelledBy: 'modal-title',
               ariaDescribedBy: 'modal-body',
               templateUrl: 'app/users/editUser/editUser.html',
               controller: 'EditCompanyUserCtrl',
               resolve: {
                   userToEdit: function () {
                       return  user;
                   }
               }
           });
           modalInstance.result.then(function (selectedItem) {
               $log.info(selectedItem);
               $ctrl.getEmployees();
           }, function (result) {
               $log.info(result);
               $log.info('Modal dismissed at: ' + new Date());
           });
       };
   },
    templateUrl:'app/users/users/uses.tpl.html'
});
