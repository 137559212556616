angular.module('scheduler')
  .component('historyNotificationList',{
    templateUrl:'app/scheduler/historyNotification/historyNotification.tpl.html',
    bindings: {
      installationId: '=',
      notifications:'='
    },
    controller:function ($uibModal,$log,HistoryNotification,toastr,PAGE_SIZE) {
      var $ctrl=this;
      
      $ctrl.refreshNotification=function () {
        return HistoryNotification.query({installationId:$ctrl.installationId,limit:$ctrl.notifications.length,offset:0}).$promise.then(function (data) {
          $ctrl.notifications=data;
          return $ctrl.notifications;
        });
      };
      $ctrl.$onInit=function () {
        //starting
        $ctrl.pageParams={installationId:$ctrl.installationId,limit:PAGE_SIZE,offset:0};
      };
      $ctrl.next=function(){
        $ctrl.pageParams.offset+=PAGE_SIZE;
        return HistoryNotification.query($ctrl.pageParams).$promise.then(function (data) {
          angular.forEach(data,function(item){
            $ctrl.notifications.push(item);
          });
          return $ctrl.notifications;
        });
      };
    }
  });
