/* eslint-disable angular/controller-as */

angular.module('maps')
    .controller('ChartModalCtrl', function ($uibModalInstance, $scope, $uibModal, $log, blockUI, toastr, ViewPluginService, symbol, installationId, $filter) {
        $scope.symbol = symbol;
        $log.info(symbol);
        $scope.installationId = installationId;
        $scope.period = 'today';
        $scope.convertPeriodToFilter = function (period) {
            var filter = null;
            var date = new Date();
            date.setHours(0, 0, 0, 0);
            switch (period) {
                case 'today':
                    filter = date;
                    break;
                case '7days':
                    date.setDate(date.getDate() - 7);
                    filter = date;
                    break;
                case 'month':
                    date.setMonth(date.getMonth() - 1);
                    filter = date;
                    break;
                case '3month':
                    date.setMonth(date.getMonth() - 3);
                    filter = date;
                    break;
                case '8h':
                    date = new Date();
                    date.setHours(date.getHours() - 8);
                    filter = date;
                    break;
            }
            return filter;
        };
        $scope.chartOptions = {
            elements: {line: {fill: false},point:{radius:0}},
            scales: {
                yAxes: [{
                    ticks: {
                        min: $scope.symbol.r_symbolconfiguration.min,
                        max: $scope.symbol.r_symbolconfiguration.max,
                        callback: function (value, index, values) {
                            return value + ' ' + $scope.symbol.r_symbolconfiguration.unit;
                        }
                    }
                }]
            },
            pan: {enabled: true, mode: 'xy'},
            zoom: {enabled: true, mode: 'xy'}
        };
        //$scope.data=[[]];
        //$scope.labels=[];
        $scope.retrieveData = function () {
            blockUI.start();
            ViewPluginService.getChartData($scope.symbol.r_command, $scope.installationId, $scope.convertPeriodToFilter($scope.period)).then(function (result) {
                $scope.data = [[]];//[0].length=0;
                $scope.labels = [];//.length=0;
                $scope.series = [$scope.symbol.name];
                result.forEach(function (element) {
                    // noinspection JSUnresolvedVariable
                    $scope.labels.push($filter('date')(element[0], 'dd/MM/yyyy HH:mm:ss'));
                    $scope.data[0].push({
                        x: new Date(element[0]),
                        y: element[1]
                    });
                });
                blockUI.stop();
            }).catch(function (err) {
                $log.error(err);
                // noinspection SpellCheckingInspection
                toastr.error('Errore', 'Errore Generico, riprovare più tardi');
                blockUI.stop();
            });
        };
        $scope.retrieveData();
        $scope.ok = function () {
            $uibModalInstance.close();
        };
    });
