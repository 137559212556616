/* eslint-disable angular/controller-as-route */
angular.module('installations',['ngConstants','angularFileUpload','ui.router', 'permission', 'permission.ui','ui.bootstrap','shared','auth','maps','views'])
  .config(function($stateProvider) {
    $stateProvider
      .state('installations', {
        url:'/installations',
        parent:'withHeader',
        component:'installationList'
      })
      .state('groupList', {
        url: '/installations/:installationId/groups',
        parent:'withHeader',
        component: 'groupList',
        resolve: {
          installationId:function($transition$){
            return $transition$.params().installationId;
          },
          groups: function(Group,installationId) {
            return Group.query({installationId:installationId}).$promise;
          },
          rootMapSymbol:function(ViewPluginService,MapService,installationId){
              return ViewPluginService.getStartingMapsInfo(installationId).then(function(value){
                  return  MapService.map.getNodeSymbols(installationId, value.installationInfo.startMapId);
              });
          }  
        },
        data: {
          permissions: {
            only: ['GROUP-MANAGEMENT'],
            redirectTo: 'installations'
          }
        }
      });
    // $locationProvider.html5Mode( true )
    //     .hashPrefix( '!' );
  });

