/* eslint-disable */
angular.module('auth')
  .controller('UserInfoInstanceCtrl', function ($uibModalInstance, user, $scope) {
  $scope.user = user;
  $scope.ok = function () {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
});
