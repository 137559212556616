angular.module('views')
    .service('ViewPluginService', function ViewPluginService(BASE_PATH, UserService, $q, $interval, $http, $log) {
        var _getInstallationViewsCounters = function (installationId) {
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId + '/views/counters').then(function (response) {
                return response.data;
            });
        };
        var _getQueryData = function (installationId, query,groupId,dateTo,dateFrom,plant) {
            var dataUrl = query.Data.Url.split('.')[1];
            if(dataUrl==='/query/events'){
                dataUrl+='/'+groupId+'?from='+dateFrom.toISOString()+'&to='+dateTo.toISOString();
                if (plant){
                    dataUrl+='&plant='+plant;
                }
            }
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId + dataUrl).then(function (result) {
                return result.data;
            });
        };
        var _getCommand = function (commandCode, installationId) {
            if (commandCode && angular.isString(commandCode) && commandCode.length) {
                return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/query/command', {'description': commandCode}).then(function (result) {
                    return result.data;
                });
            } else {
                //api do not handle command request is commandCode is empty -->avoid to send it TODO fix api
                var deferred = $q.defer();
                deferred.resolve([]);
                return deferred.promise;
            }
        };
        var _getNoteList = function (commandCode, installationId) {
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/query/Notelist', {'description': commandCode}).then(function (result) {
                return result.data;
            });
        };
        var _getPropertyList = function (commandCode, installationId) {
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/query/propertylist', {'description': commandCode}).then(function (result) {
                return result.data;
            });
        };
        var _getChartData = function (commandCode, installationId, after) {
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/query/chartData', {
                description: commandCode,
                after: after
            }).then(function (result) {
                return result.data;
            });
        };
        var _getMapSymbol = function (commandCode, installationId) {
            return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/query/mapSymbol', {'description': commandCode}).then(function (result) {
                return result.data;
            });
        };
        var _sendCommand = function (command, plantId, destination_role, installationId, toAddFields) {
            if (!command && !plantId) {
                throw new Error('invalid command');
            }
            var com = {
                'PLANT_ID': plantId,
                'MSG': command,
                'ROLE': destination_role,
                'INSTALLATION_ID': installationId,
                'SOURCE': UserService.getUserUsername()
            };
            angular.forEach(toAddFields, function (key, value) {
                com[key] = value;
            });
            /*for (var i in toAddFields) {
              com[i] = toAddFields[i];
            }*/
            $log.info(com);
            return $http.post(BASE_PATH.API_URL + 'cmdview', com).then(function (response) {
                return response.data;
            });
        };
        var _getStartingMapsInfo = function (installationId, mapsId) {
            if (mapsId) {
                return new Promise(function (resolve) {
                    resolve({
                        installationId: installationId,
                        installationInfo: {
                            startMapId: mapsId
                        },
                        views: {}
                    });
                });
            } else {
                return _getInstallationViewsInfo(installationId);
            }
        };
        var _getInstallationViewsInfo = function (installationId) {
            return $http.get(BASE_PATH.API_URL + 'installation/' + installationId).then(function (response) {
                // noinspection JSUnresolvedVariable
                return {
                    installationId: installationId,
                    installationInfo: response.data[0],
                    views: response.data[0].viewsInfo.Views
                };
            });
        };
        return {
            getStartingMapsInfo: _getStartingMapsInfo,
            getInstallationViewsCounters: _getInstallationViewsCounters,
            getQueryData: _getQueryData,
            getInstallationViewsInfo: _getInstallationViewsInfo,
            sendCommand: _sendCommand,
            getCommand: _getCommand,
            getPropertyList: _getPropertyList,
            getNoteList: _getNoteList,
            getMapSymbol: _getMapSymbol,
            getChartData: _getChartData,
            sendDefaultCommand: function (command, installationId,plantId) {
                return $http.post(BASE_PATH.API_URL + 'installation/' + installationId + '/query/defaultCommand', {command: command,plantId:plantId}).then(function (result) {
                    return result.data;
                });
            }
        };
    });
