/* eslint-disable angular/controller-as,angular/controller-as-vm,angular/no-services*/

angular.module('plants')
  .controller('EditPlantModalCtrl', function ($q, $uibModal, $log, modalService, $http,BASE_PATH, $uibModalInstance, plantToEdit, installationId, $scope) {
    var $ctrl = this;
    if (plantToEdit){
      $scope.plantMaster = plantToEdit;
      $scope.plant = angular.copy($scope.plantMaster);
    } else {
      $scope.isNew = true;
      $scope.plant = {isNew : true, typeId : 2};
    }
    $scope.installationId=installationId;

    var partitionsP = $http.get(BASE_PATH.API_URL+'installation/'+installationId+'/partitions'),
      templatesP = $http.get(BASE_PATH.API_URL+'plant/types');

    $q.all([partitionsP, templatesP]).then(function(arrayOfResults) {
      $scope.partitions = arrayOfResults[0].data;
      $scope.types = arrayOfResults[1].data;
      $log.info($scope.partitions);

      //associo di default la partizione
      if(angular.isUndefined($scope.plant.partitionId)){
        if ($scope.partitions.length){
          $scope.plant.partitionId = $scope.partitions[0].idpartition;
        }else{
          $log.error('partition not defined');
          $scope.errorMsg = 'Partizione Predefinita non esistente.';
        }
      }
      $log.info($scope.plant);
    });
    $scope.typeChanged = function(t){
      $scope.plant.typeId = t;
    };
    $scope.partitionChanged = function(p){
      $scope.plant.partitionId = $scope.selectedPartId;
    };
    $scope.deletePlant = function(){
      var modalOptions = {
        closeButtonText: 'Annulla',
        actionButtonText: 'Sì',
        headerText: 'Eliminare ?',
        bodyText: 'Sicuro di volere eliminare questa centrale?'
      };
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/shared/popup/confirmModal/confirmModal.tpl.html',
        controller: 'ConfirmModalInstanceCtrl',
        resolve: {
          message: function () {
            return  modalOptions;
          }
        }
      });

      modalInstance.result.then(function () {
        $http.delete(BASE_PATH.API_URL+'plant/'+$scope.plant.MAC).then(function(data){
          $uibModalInstance.close($scope.plant);
        }, function(error){
          $scope.errorMessage = error.data.error;
        });

      }, function (result) {

      });
    };
    $scope.ok = function () {
      $log.info('saving',$scope.plant);

      if (!$scope.isNew){
        $http.post(BASE_PATH.API_URL+'plant/'+$scope.plant.MAC, $scope.plant).then(function(){
        //TODO inserire loader
          $uibModalInstance.close($scope.plant);
        }, function(error){
          $scope.errorMessage = error.data.error;
        });

      } else {
        $http.post(BASE_PATH.API_URL+'plant', $scope.plant).then(function(){
        //TODO inserire loader
          $uibModalInstance.close($scope.plant);
        }, function(error){
          $scope.errorMessage = error.data.error;
        });
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.animationsEnabled = true;
  });
