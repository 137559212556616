angular.module('views', [ 'shared', 'ui.grid', 'ui.grid.selection', 'ui.grid.resizeColumns', 'ui.grid.autoResize', 'plants', 'auth' ])
    .config(function($stateProvider){
        // eslint-disable-next-line angular/controller-as-route
        $stateProvider
            .state('views', {
                url: '/installation/:installationId/views/:viewId',
                parent: 'withHeader',
                controller: 'ViewsCtrl',
                templateUrl: 'app/views/view/view.tpl.html',
                resolve: {
                    installationId: function($stateParams){
                        return $stateParams.installationId;
                    },
                    plants: function(installationId,PlantService ){
                        return PlantService.plantListByInstallationId(installationId).then(function(response){
                            return response.result;
                        });
                    },
                    viewId: function($stateParams){
                        return $stateParams.viewId;
                    },
                    sock: function($stateParams, socketService){
                        return socketService.connect($stateParams);
                    }
                },
                data: {
                    permissions: {
                        only: [ 'BUTTON-INSTALLAZIONI-APRIVISTE' ],
                        redirectTo: 'installations'
                    }
                }
            })
            .state('standAloneView', {
                url: '/installation/:installationId/view/:viewId?symbolId',
                parent: 'withoutHeader',
                controller: 'StandAloneViewCtrl',
                templateUrl: 'app/views/standAloneView/standAloneView.tpl.html',
                resolve: {
                    installationId: function($stateParams){
                        return $stateParams.installationId;
                    },
                    plants: function(installationId,PlantService ){
                        return PlantService.plantListByInstallationId(installationId).then(function(response){
                            return response.result;
                        });
                    },
                    viewId: function($stateParams){
                        return $stateParams.viewId;
                    },
                    sock: function($stateParams, socketService){
                        return socketService.connect($stateParams);
                    }
                }
            });
    });
