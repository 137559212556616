angular.module('dashboard',['views','maps','ui.router', 'permission', 'permission.ui','auth','ngFitText'])
  .config(function($stateProvider) {
    // eslint-disable-next-line angular/controller-as-route
    $stateProvider
      .state('dashboard', {
        url: '/dashboard/:installationId',
        parent: 'withHeader',
        controller: 'dashboardInstallationCtrl',
        templateUrl: 'app/dashboard/dashboard/dashboard.tpl.html',
        resolve: {
          installationId: function ($stateParams) {
            return $stateParams.installationId;
          },
          sock: function ($stateParams, socketService) {
            return socketService.connect($stateParams);
          }
        },
        data: {
          permissions: {
            only: ['BUTTON-INSTALLAZIONI-DASHBOARD'],
            redirectTo: 'installations'
          }
        }
      });
  });
