angular.module('maps',['ngConstants','angularFileUpload','ui.router', 'permission', 'permission.ui','ui.bootstrap','shared','auth','chart.js'])
  .config(function($stateProvider) {
    // eslint-disable-next-line angular/controller-as-route
    $stateProvider
        .state('maps', {
            url:'/installation/:installationId/maps',
            parent:'withHeader',
            controller: 'MapsCtrl',
            templateUrl: 'app/maps/map/map.tpl.html',
            resolve: {
                installationId:function($stateParams){
                    return $stateParams.installationId;
                },
                mapId:function(){
                    return undefined;
                },
                sock: function ($stateParams, socketService) {
                    return socketService.connect($stateParams);
                },
                showNavigator:function(installationId,UserService){
                    return UserService.showMapNavigator(installationId);
                }
            },
            data: {
                permissions: {
                    only: ['BUTTON-INSTALLAZIONI-APRIMAPPE'],
                    redirectTo: 'installations'
                }
            }
        })
      .state('maps.map', {
        url:'/:mapId',
        controller: 'MapsCtrl',
        templateUrl: 'app/maps/map/map.tpl.html',
        resolve: {
          mapId:function($stateParams){
            return $stateParams.mapId;
          }
        }
      })
      .state('standAloneMap', {
        url:'/installations/:installationId/map/:mapId?symbolId',
        parent:'withoutHeader',
        controller: 'standAloneMapCtrl',
        templateUrl: 'app/maps/standAloneMap/standAloneMap.tpl.html',
        resolve: {
          installationId:function($stateParams){
            return $stateParams.installationId;
          },
          mapId:function($stateParams){
            return $stateParams.mapId;
          },
          sock: function ($stateParams, socketService) {
            return socketService.connect($stateParams);
          },
          symbolId:function ($stateParams) {
            return $stateParams.symbolId;
          }
        },
        data: {
          permissions: {
            only: ['BUTTON-INSTALLAZIONI-APRIMAPPE'],
            redirectTo: 'installations'
          }
        }
      });
  });

