angular.module('scheduler')
    .component('pendingNotificationList', {
        templateUrl: 'app/scheduler/pendingNotification/pendingNotificationList.tpl.html',
        bindings: {
            installationId: '=',
            pendingNotifications: '='
        },
        controller: function($uibModal, $log, PendingNotification, toastr){
            var $ctrl = this;
            $ctrl.refreshNotification = function(){
                return PendingNotification.query({installationId: $ctrl.installationId}).$promise.then(function(data){
                    $ctrl.pendingNotifications = data;
                    return $ctrl.pendingNotifications;
                });
            };
            $ctrl.$onInit = function(){
                //starting
            };
            $ctrl.takeOver = function(notification){
                $log.debug(notification);
                var modalInstance = $uibModal.open({
                    component: 'takeOver',
                    size: 'lg',
                    resolve: {
                        notification: function(){
                            return notification;
                        },
                        installationId: function(){
                            return $ctrl.installationId;
                        }
                    }
                });
                modalInstance.result.then(function(notes){
                    $log.info(notes);
                    notification.notes = notes;
                    notification.installationId = $ctrl.installationId;
                    notification.$save().then(function(){
                        toastr.success('Notifica Correttamente presa in carico', '');
                        $ctrl.refreshNotification();
                    }).catch(function(error){
                        $log.error(error);
                        toastr.error('Problema Imprevisto, riprova piu tardi', '');
                    });
                }, function(result){
                    $log.info(result);
                    $log.info('Modal dismissed at: ' + new Date());
                });
            };
        }
    });
