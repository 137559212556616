/* eslint-disable angular/controller-as */

angular.module('maps')
  .controller('MapCommandPopupCtrl', function (symbol, commands, $uibModalInstance, $scope,$uibModal) {
    $scope.commands = commands;
    $scope.data={};
    $scope.commands.push({r_description:'Proprietà',r_protocol:'GEMSS_PROPERTY_LIST'});
    $scope.ok = function(){
      $uibModalInstance.close($scope.data.selectedCommand);
    };
    if(symbol.graph){
        $scope.commands.push({r_description: 'Storico', r_protocol: 'CHART'});
    }
    if(!(symbol instanceof ModBusSymbol)){
      $scope.commands.push({r_description:'Note Operative', r_protocol:'GEMSS_NOTE_LIST'});
    }else{
      $scope.ok=function(){
        if($scope.data.selectedCommand.r_protocol!=='CHART'){
            $uibModal.open({
                animation: true,
                templateUrl: 'app/shared/askValueModal/askValueModal.html',
                controller: 'askValueModalCtrl',
                resolve: {
                    options: function(){
                        return {
                            title: 'Inserire valore per comando ' + $scope.data.selectedCommand.r_description,
                            label: 'valore'
                        };
                    }
                }
            }).result.then(function(data){
                $scope.data.selectedCommand.r_protocol = $scope.data.selectedCommand.r_protocol.replace('x', data.value.toString());
                $uibModalInstance.close($scope.data.selectedCommand);
            });
        }else{
            $uibModalInstance.close($scope.data.selectedCommand);
        }
      };
    }
    $scope.symbol = symbol;
    $scope.cancel= $uibModalInstance.dismiss;
  });
