/* eslint-disable angular/controller-as,angular/no-services */

angular.module('managers')
  .controller('ManagerManagementCtrl', function (fileReader, $window, $uibModal, $log, $scope, BASE_PATH, $http) {
    $scope.getManagers = function(){
      $http.get(BASE_PATH.API_URL+'manager').then(function(data){
        $scope.customers = data.data;
      });
    };
    $scope.getManagers();
    $scope.newManager = function(){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/manager/newManager/newManager.tpl.html',
        controller: 'NewManagerModalCtrl',
        resolve: {
          type : function() {
            return 'manager';
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getManagers();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
    $scope.editCustomer = function(customer){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/customer/editCustomer/editCustomer.html',
        controller: 'EditCustomerModalCtrl',
        resolve: {
          customerToEdit: function () {
            return  customer;
          },
          type : function() {
            return 'manager';
          }
        }
      });
      modalInstance.result.then(function (selectedItem) {
        $log.info(selectedItem);
        $scope.getManagers();
      }, function (result) {
        $log.info(result);
        $log.info('Modal dismissed at: ' + new Date());
      });
    };


  });
