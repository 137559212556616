angular.module('shared')
    .service('socketService', function (BASE_PATH, $q, $rootScope, $log, UserService, $timeout, toastr, $window) {
        var sock;
        var pingMsg = 'ping_';
        var pongMsg = 'pong_';
        var keepAliveTimeoutValue = 4000;
        var keepAliveTimeout;
        var waiting = true;
        var connect = function () {
            var deferred = $q.defer();
            if (angular.isDefined(sock)) {
                deferred.resolve(sock);
                return deferred.promise;
            }
            var openingSock = new SockJS(path + '?sess=' + encodeURIComponent(UserService.getUserToken())); //TODO FIXME REMEMBER
            openingSock.onopen = function () {
                $log.info('RT Socket Open. ');
                $timeout(sendPing, keepAliveTimeoutValue * 2);
                //handleMessage({data:JSON.stringify({MSG:{subject:'oggetto',body:'corpo',TYPE:'NOTIFICATION'}})});
                deferred.resolve(openingSock);
            };
            openingSock.onmessage = handleMessage;
            openingSock.onerror = function (e) {
                $log.error(e);
            };
            openingSock.onclose = function (e) {
                e = e || {};
                e.code = 'E' + (e.code || '001');
                $log.error('RT Socket Closed. ', e);
                if (keepAliveTimeout) {
                    $timeout.cancel(keepAliveTimeout);
                }
                $window.location.reload(true);
            };
            return deferred.promise;
        };
        var factory = {
            connect: connect,
            subscribe: function (topic) {
                factory.send({'subscribe': topic});
                return topic;
            },
            unsubscribe: function (topic) {
                factory.send({'unsubscribe': topic});
            },
            send: function (msgString) {
                if (!angular.isString(msgString)) {
                    try {
                        msgString = angular.toJson(msgString);
                    } catch (e) {
                        $log.debug(msgString + ' is not a json');
                    }
                }
                factory.connect().then(function (sock) {
                    sock.send(msgString);
                });
            }
        };

        function handleSoundNotification(data) {
            $rootScope.$emit('socket:sound', {
                'installationId': 'no-installation-id',//TODO check if exists
                'data': angular.fromJson(data.MSG || {Number: 1})
            });
        }

        var path = BASE_PATH.SOCK_PATH.includes('http') ? BASE_PATH.SOCK_PATH : BASE_PATH.API_URL + BASE_PATH.SOCK_PATH;
        var openNotificationToastr = function (message) {
            /** @namespace message.InstallationId */
            var settings = UserService.getGroupSettingsByInstallationId(message.InstallationId);
            var level = message.level||message.notification_level || 'warning';
            if (settings && settings.enable_pop_up_notification) {
                toastr[level](message.body || '', message.subject || 'Attenzione', {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    closeIcon: false,
                    allowHtml: true,
                    closeButton: true,
                    tapToDismiss: false,
                    toastClass: 'toast no-opacity',
                    closeHtml: '<button><i class="fa fa-times-circle"></i></button>'
                });
                /** @namespace message.if_popup_sound */
                if (message.if_popup_sound && settings.sound_on_pop_up_notification) {
                    handleSoundNotification({MSG: {Number: 1}});
                }
            }

        };
        /* keep for debug reason
        var openFakeToastr = function () {
            openNotificationToastr({
                body: ' impianto Demo  <br><br>  ATTIVAZIONE ZONA  <br> 0011 -  Zona Semplice 11 <br><br> ' + new Date().toLocaleString(),
                subject: 'Installazione prova'
            });
            $timeout(function(){
            openFakeToastr();
            },10000);
        };
        openFakeToastr();
        */
        /* views sound debug
        var runSound=function(){
            console.log("AAAAAAAAA");
            handleSoundNotification({MSG:{Number:-1}});
        };
        runSound();
         */


        var handleMessage = function (e) {
            if (e.data.includes(pongMsg)) {
                waiting = false;
            } else {
                $log.info('Incoming Message', e.data);
                var data = angular.fromJson(e.data);
                if (angular.isUndefined(data.MSG)) {
                    if (angular.isDefined(data.subscribedTopic)) {
                        $log.info('subscribed to ', data);
                    } else {
                        $log.error('unrecognized event from ws');
                    }
                    return;
                }
                var gemssMsg = data.MSG;
                if (angular.isString(gemssMsg)) {
                    gemssMsg = angular.fromJson(gemssMsg);
                }
                var type = gemssMsg.TYPE;
                switch (type) {
                    case 'COM90':
                        data.MSG = angular.fromJson(gemssMsg.MSG);
                        $rootScope.$emit('socket:com90', data);
                        break;
                    case 'VIEWS':
                        $rootScope.$emit('socket:view', {
                            'installationId': data.INSTALLATION_ID,
                            'data': angular.fromJson(gemssMsg.MSG)
                        });
                        break;
                    case 'SMOWKE':
                        $rootScope.$emit('socket:map', {
                            'installationId': 'no-installation-id',
                            'data': angular.fromJson(gemssMsg.MSG)
                        });
                        break;
                    case 'SOUND':
                        handleSoundNotification(angular.fromJson(gemssMsg));
                        break;
                    case 'NOTIFICATION':
                        openNotificationToastr(gemssMsg);
                        break;
                    case 'REFRESH':
                        var msg=gemssMsg.MSG;
                        if (msg.length){
                            msg=angular.fromJson(gemssMsg.MSG);
                        }
                        $rootScope.$emit('socket:refresh', {
                            'installationId': data.INSTALLATION_ID,
                            'data': msg
                        });
                        break;
                    default:
                        $log.error('not recognized message from ws');
                        break;
                }
            }
        };
        var sendPing = function () {
            factory.send(pingMsg);
            waitForPong();
        };
        var waitForPong = function () {
            if (angular.isDefined(keepAliveTimeout)) {
                $timeout.cancel(keepAliveTimeout);
            }
            keepAliveTimeout = $timeout(function () {
                if (waiting) {
                    $log.error('timeout on keep alive');
                    if (sock) {
                        factory.connect().then(function (sock) {
                            sock.onclose();
                        });
                    }
                } else {
                    waiting = true;
                    sendPing();
                }
            }, keepAliveTimeoutValue);
        };
        sock = connect();
        return factory;
    });

